import { useService } from "@/setup/connectClient";
import {
  DocumentCustomController,
  DocumentController,
  TagController,
  DocumentTypeController,
  DocumentStatusController,
} from "@socotec.io/socio-grpc-api/connect/services/document/document_pb";
import {
  DocumentUpdater,
  listAll,
} from "@socotec.io/socio-grpc-api/connect/utils";

const documentCustomClient = useService(DocumentCustomController);
const documentClient = useService(DocumentController);
const tagClient = useService(TagController);
const documentTypeClient = useService(DocumentTypeController);
const documentStatusClient = useService(DocumentStatusController);

// == ============ ==
// ** Document API **
// == ============ ==

/**
 *
 * @param {*} metadata
 * @returns
 */
export const retrieveDocument = async (
  uuid,
  metadata = {},
  failIfNoFound = true,
) => {
  try {
    const response = await documentCustomClient.documentCustomExtendRetrieve(
      { uuid },
      { headers: metadata },
    );
    return response;
  } catch (error) {
    if (error.code === 5 && !failIfNoFound) {
      return undefined;
    }
    throw error;
  }
};

/**
 *
 * @param {*} uuid
 * @param {*} metadata
 * @param {*} failIfNoFound
 * @returns
 */
export const retrieveRetrieveDocumentUrl = async (
  uuid,
  metadata = {},
  failIfNoFound = true,
) => {
  try {
    const response = await documentCustomClient.retrieveDocumentCustomUrl(
      { uuid },
      { headers: metadata },
    );
    return response;
  } catch (error) {
    if (error.code === 5 && !failIfNoFound) {
      return undefined;
    }
    throw error;
  }
};

/**
 *
 * @param {*} metadata
 * @returns
 */
export const fetchDocuments = async (metadata = {}) => {
  const response = await documentCustomClient.documentCustomExtendList(
    {},
    { headers: metadata },
  );
  return response;
};

/**
 *
 * @param {*} metadata
 * @returns
 */
export const fetchDocumentsFromObjectStorage = async (metadata = {}) => {
  const response = await documentClient.listDocumentInObjectStorage(
    {},
    { headers: metadata },
  );
  return response;
};

/**
 *
 * @param {*} caseData
 * @param {*} metadata
 * @returns
 */
export const createManualDocument = async (docData, metadata = {}) => {
  const response = await documentCustomClient.createWithDocument(docData, {
    headers: metadata,
  });
  return response;
};

/**
 *
 * @param {*} caseData
 * @param {*} metadata
 * @returns
 */
export const createBimdataDocument = async (
  doc,
  docCustom,
  file,
  fileName,
  callback,
) => {
  const uploader = new DocumentUpdater(useService, file, doc, docCustom);

  uploader.onprogress = callback;

  return await uploader.upload();
};

export const updateDocument = async (documentUuid, docData, metadata = {}) => {
  const response = await documentCustomClient.updateDocumentCustomExtend(
    { ...docData, uuid: documentUuid },
    { headers: metadata },
  );
  return response;
};

export const bulkUpdateDocuments = async (documents, metadata = {}) => {
  const response = await documentCustomClient.bulkUpdateDocuments(documents, {
    headers: metadata,
  });
  return response;
};

/**
 *
 * @param {*} documentUuid
 * @param {*} metadata
 * @param {*} failIfNoFound
 * @returns
 */
export const destroyDocument = async (
  documentUuid,
  metadata = {},
  failIfNoFound = true,
) => {
  try {
    const response = await documentCustomClient.destroy(
      { uuid: documentUuid },
      { headers: metadata },
    );
    return response;
  } catch (error) {
    if (error.code === 5 && !failIfNoFound) {
      return undefined;
    }
    throw error;
  }
};

// == ============ ==
// ** TAG API **
// == ============ ==

/**
 *
 * @param {*} metadata
 * @returns
 */
export const fetchDocumentTags = async (metadata = {}) => {
  const response = await tagClient.list({}, { headers: metadata });
  return response;
};

// DOCUMENT TYPE API

export const listDocumentTypes = async () => {
  const response = await listAll(documentTypeClient.list, {}, {});
  return response;
};

// DOCUMENT STATUS API

export const listDocumentStatus = async () => {
  const response = await listAll(documentStatusClient.list, {}, {});
  return response;
};
