import BuildingAsset from "@/models/BuildingAsset";
import SiteAsset from "@/models/SiteAsset";
import SpaceAsset from "@/models/SpaceAsset";
import StoreyAsset from "@/models/StoreyAsset";
import ZoneAsset from "@/models/ZoneAsset";
import { utils } from "@socotec.io/socio-aos-component";

export const ASSET_ITEMS = {
  site: {
    ...utils.aosConst.AOS_ITEMS.site,
    modelClass: () => SiteAsset,
    identityCard: "SiteIdentityCard",
  },

  building: {
    ...utils.aosConst.AOS_ITEMS.building,
    modelClass: () => BuildingAsset,
    identityCard: "BuildingIdentityCard",
  },

  storey: {
    ...utils.aosConst.AOS_ITEMS.storey,
    modelClass: () => StoreyAsset,
    identityCard: "StoreyIdentityCard",
  },

  zone: {
    ...utils.aosConst.AOS_ITEMS.zone,
    modelClass: () => ZoneAsset,
    identityCard: "BaseIdentityCard",
  },

  space: {
    ...utils.aosConst.AOS_ITEMS.space,
    modelClass: () => SpaceAsset,
    identityCard: "BaseIdentityCard",
  },
};

export const streamStatuses = {
  SUCCESS: "SUCCESS",
  GENERATING: "GENERATING",
  ERROR: "ERROR",
  READY: "READY",
  SENT: "SENT",
  GENERATION_FAILED: "GENERATION_FAILED",
  UPLOAD_FAILED: "UPLOAD_FAILED",
};

export const OPERATORS = [
  { label: "=", value: "eq" },
  { label: "<", value: "lt" },
  { label: ">", value: "gt" },
];

export const CONSISTENCY_DATA_MAPPING = {
  ENVELOPPE: {
    configurator: ["buildingAddress"],
    identityCard: {
      mission: ["caseNumber", "missionName", "thermalRegulationType"],
      projectOwner: [
        "projectOwnerFirstName",
        "projectOwnerLastName",
        "projectOwnerAddress",
        "projectOwnerPostcode",
        "projectOwnerCity",
      ],
    },
    buildingInfo: [
      "ceilingHeight",
      "vmcRegulationType",
      "vmcType",
      "buildingType",
    ],
    permea: ["housingList"],
    document: ["xmlBE"],
  },
  DPE: {
    configurator: ["buildingAddress"],
    identityCard: {
      mission: ["caseNumber", "missionName", "thermalRegulationType"],
      projectOwner: [
        "projectOwnerFirstName",
        "projectOwnerLastName",
        "projectOwnerAddress",
        "projectOwnerPostcode",
        "projectOwnerCity",
      ],
    },
    buildingInfo: [
      "visitDate",
      "ceilingHeight",
      "vmcRegulationType",
      "totalLivingArea",
      "vmcType",
      "buildingType",
      "heatingType",
    ],
    dpe: ["housingList"],
    document: ["xmlBE"],
  },
  VENTILATION: {
    configurator: ["buildingAddress"],
    identityCard: {
      mission: ["caseNumber", "missionName", "thermalRegulationType"],
      projectOwner: [
        "projectOwnerFirstName",
        "projectOwnerLastName",
        "projectOwnerAddress",
        "projectOwnerPostcode",
        "projectOwnerCity",
      ],
    },
    buildingInfo: [
      "visitDate",
      "referential",
      "vmcRegulationType",
      "totalLivingArea",
      "buildingType",
      "vmcType",
      "missionType",
    ],
    permea: ["housingList"],
    document: ["xmlBE"],
  },
  RE2020: {
    configurator: ["buildingAddress"],
    identityCard: {
      mission: ["caseNumber", "missionName", "thermalRegulationType"],
      projectOwner: [
        "projectOwnerFirstName",
        "projectOwnerLastName",
        "projectOwnerAddress",
        "projectOwnerPostcode",
        "projectOwnerCity",
      ],
    },
    buildingInfo: [
      "visitDate",
      "ceilingHeight",
      "vmcRegulationType",
      "totalLivingArea",
      "vmcType",
      "buildingType",
      "heatingType",
    ],
    re2020: ["housingList"],
    document: ["xmlBE"],
  },
};
