import * as atmBackApi from "@/api/atm-back";
import * as documentBackApi from "@/api/document-back";
import Document from "@/models/Document";
import { restDocumentClient } from "@/setup/restDocumentClient";
import { filterDocuments } from "@/utils/helpers";
import { utils } from "@socotec.io/socio-vue-components";

const state = {
  documentSelected: [],
  documentTags: [],
  documentsCount: 0,
  documentTablePageSize: 10,
  backendOrdering: true,
};

const getters = {
  getDocuments: () => Document.query().get(),
  getInfiltreaDocuments: (state) => {
    const documentsQuery = Document.query();
    if (state.backendOrdering) {
      return documentsQuery
        .limit(state.documentTablePageSize)
        .where("name", filterDocuments)
        .get();
    } else {
      return documentsQuery
        .orderBy("createdAt", "desc")
        .limit(state.documentTablePageSize)
        .where("name", filterDocuments)
        .get();
    }
  },
  getDocumentSelected: (state) => {
    return state.documentSelected;
  },
  getDocumentTags: (state) => {
    return state.documentTags;
  },
  getDocumentCount: (state) => {
    return state.documentsCount;
  },
  getDocumentTablePageSize: (state) => {
    return state.documentTablePageSize;
  },
  getDocumentsByUuids: () => (uuids) => {
    return Document.query().whereIdIn(uuids).get();
  },
  getLastCreatedDocument: () => {
    return Document.query().orderBy("createdAt", "desc").first();
  },
};

const actions = {
  setSelectedDocument({ commit }, documents) {
    commit("SET_SELECTED_DOCUMENTS", documents);
  },
  async fetchDocumentTags({ commit }, { metadata }) {
    const response = await documentBackApi.fetchDocumentTags(metadata);
    commit("SET_TAGS", response.results);
  },

  /**
   * Fetch document by id
   * @param [_]
   * @param uuid
   * @returns {Promise<Document>}
   */
  async fetchDocument(_, uuid) {
    const data = await documentBackApi.retrieveDocument(uuid);
    return await Document.insertOrUpdate({ data });
  },

  /**
   *
   * @param {*} param0
   * @param {*} param1
   * @returns
   */
  async fetchDocumentList({ commit }, { metadata, createData = true }) {
    const response = await documentBackApi.fetchDocuments(metadata);
    let documents = response.results;

    if (!createData) return documents;

    await Document.deleteAll();
    await Document.create({ data: documents });

    commit("UPDATE_DOCUMENT_COUNT", response.count);
  },

  /**
   * Create a Bimdata document
   * @param rootGetters
   * @param operationId
   * @param datas
   * @returns {Promise<{documents: Document[]}>}
   */
  async createBimdataDocument(
    rootGetters,
    { operationId, callback, ...datas },
  ) {
    const doc = {
      ...datas.form,
      gedProjectId: datas.gedProjectId,
      cloudId: process.env.VUE_APP_CLOUD_ID,
      relatedObjects: [operationId],
      servicesRelatedName: [process.env.VUE_APP_ATM_SERVICE_ID],
    };

    const docCustom = {
      uuid: doc.uuid,
      relatedObjects: [operationId],
      docType: datas.form.docType,
      docStatus: datas.form.docStatus,
      tags: datas.form.tags,
    };

    try {
      await documentBackApi.createBimdataDocument(
        doc,
        docCustom,
        datas.file,
        datas.file.name,
        callback,
      );
    } catch (error) {
      console.error(error);
      throw new Error();
    }
  },
  /**
   * Create a manual document
   * @param context
   * @param operationId
   * @param datas
   * @returns {Promise<{documents: Document[]}>}
   */
  async createManualDocument(context, { operationId, ...datas }) {
    // INFO - MS - 22/07/2021 - GRPC call on microservice Document
    const docData = {
      ...datas.form,
      relatedObjects: [operationId],
      servicesRelatedName: [process.env.VUE_APP_ATM_SERVICE_ID],
    };

    try {
      const response = await documentBackApi.createManualDocument(docData);
      return await Document.insertOrUpdate({ data: response });
    } catch (error) {
      console.error(error);
      throw new Error();
    }
  },
  async updateDocument(context, data) {
    if (data.file) {
      if (data.form.origin === "MANUAL") {
        data.form = {
          ...data.form,
          origin: "BIMDATA_GED",
          gedProjectId: data.gedProjectId,
          cloudId: process.env.VUE_APP_CLOUD_ID,
        };
      }
      return await context.dispatch("updateDocumentOnBimdata", data);
    }
    try {
      const response = await documentBackApi.updateDocument(
        data.form.uuid,
        data.form,
      );
      await Document.insertOrUpdate({ data: response });
    } catch (error) {
      console.error(error);
      throw new Error();
    }
  },
  async updateDocumentOnBimdata(context, data) {
    const docFile = new FormData();
    docFile.append("file", data.file);
    docFile.append("cloudId", process.env.VUE_APP_CLOUD_ID);
    data.form.servicesRelatedName = [process.env.VUE_APP_ATM_SERVICE_ID];

    const formatValue = (value) => {
      return value !== undefined ? value : "";
    };

    Object.entries(data.form).forEach(([key, value]) => {
      if (key === "metaDatas") {
        value = value ? JSON.stringify(value) : JSON.stringify({});
      }
      if (key === "docType" || key === "docStatus") {
        value = formatValue(value);
      }
      docFile.append(key, value);
    });
    try {
      const bimDataDoc = await restDocumentClient.uploadBimDocument(
        docFile,
        "put",
      );
      let result = utils.grpc.snakeToCamel(bimDataDoc.data, "flatten");
      await Document.insertOrUpdate({ data: result });
    } catch (error) {
      console.error(error);
      throw new Error();
    }
  },

  async updateMultipleDocs(context, { data, documentIds }) {
    const documents = documentIds.map((uuid) => ({
      uuid,
      ...data,
    }));

    const response = await documentBackApi.bulkUpdateDocuments({
      documents,
    });

    await Document.update({ data: response });
  },

  /**
   * Remove a document
   * @param {*} param0
   * @param {*} documentUuid
   * @returns
   */
  async removeDocument({ commit, getters }, documentUuid) {
    documentBackApi.destroyDocument(documentUuid);
    commit("UPDATE_DOCUMENT_COUNT", getters.getDocumentCount - 1);
    return await Document.delete(documentUuid);
  },
  /**
   * Get url document
   * @param {*} context
   * @param {*} docCustomUuid
   * @returns
   */
  async getDocumentUrl(context, docCustomUuid) {
    const response =
      await documentBackApi.retrieveRetrieveDocumentUrl(docCustomUuid);
    return response.documentUrl;
  },

  async getDooAppReport(_, gedProjectId) {
    const response = await atmBackApi.getDooAppReport(gedProjectId);
    return response;
  },
};

const mutations = {
  SET_SELECTED_DOCUMENTS: (state, documents) => {
    state.documentSelected = documents;
  },
  SET_TAGS: (state, tags) => {
    state.documentTags = tags.map((tag) => tag.name);
  },
  RESET_TAGS: (state) => {
    state.documentTags = [];
  },
  UPDATE_DOCUMENT_COUNT: (state, newTotal) => {
    state.documentsCount = newTotal;
  },
  UPDATE_DOCUMENT_TABLE_PAGE_SIZE: (state, pageSize) => {
    state.documentTablePageSize = pageSize;
  },
  UPDATE_DOCUMENT_ORDER_BY_BACKEND: (state, backendOrdering) => {
    state.backendOrdering = backendOrdering;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
