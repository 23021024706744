import { Workbox } from "workbox-window";

function createWorkboxInstance() {
  if ("serviceWorker" in navigator) {
    const wb = new Workbox(`${process.env.BASE_URL}service-worker.js`);

    wb.addEventListener("controlling", () => {
      window.location.reload();
    });

    wb.register();

    return wb;
  }
  return null;
}

const wb = createWorkboxInstance();

export default wb;
